nav{
    background-color: var(--bggreen);
    padding: 20px 0;
    position:fixed;
    top: 0px;
    z-index: 300;
    border-radius: 0 0 20px 20px;
}
.logo img{
    margin-right: 20px;
}
.logo a{
    font-weight: 500;
    font-size: 40px;
    color: #fff;
    margin: 0;
}
.menu ul li{
    padding-right: 30px;
    list-style: none;
}
.menu ul li a{
color: #fff;
}
.menu ul li a:hover{
    border-bottom: 2px solid #fff;
}
.menu ul :nth-child(4){
    padding: 0;
}
.menu-icon{
    display: none;
}
@media (max-width: 720px) {
    .logo a{
        font-size: 30px;
    }
    .menu ul{
        display: none !important;
    }
    .menu-icon{
        display: block;
    }
}