footer{
    background-color: var(--bggreen);
}
.footer{
    padding: 50px 0;
}
.footer .copyright h5{
    margin: 0;
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    line-height: 125%;
}

@media (max-width: 720px) {
    .footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}