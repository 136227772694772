.team{
    margin-bottom: 60px;
}
.team .content h6{
    font-size: 16px;
    color: var(--bggreen);
    font-weight: bold;
    line-height: 125%;
    letter-spacing: 5px;
}
.team .content h1{
    font-family: "Crimson Text", serif;
    font-size: 40px;
    line-height: 125%;
    font-weight: bold;
    padding: 16px 0;
}
.team .content h5{
    font-size: 20px;
    line-height: 160%;
    text-indent: 10px;
    font-weight: normal;
}
.team .card1{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px 40px;
    border-radius: 5px;
}
.card-img{
    margin-bottom: 20px;
}
.card1 h3{
    font-family: "Crimson Text", serif;
    font-weight: bold;
    font-size: 24px;
}
.card1 p{
    color: #212529;
    opacity: 75%;
    font-size: 16px;

}
.team-card{
    margin: 45px 0;
}
.btn-team a{
    font-size: 20px;
    line-height: 125%;
    padding: 13px 30px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    transition: 0.4s;

}
.btn-team a:hover{
    color: #000;
    background-color: #fff;
    border: 2px solid black;
}
@media (max-width: 720px) {
    .team-card{
        padding: 0 50px;
    }
}