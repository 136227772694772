.details1{
    margin-bottom: 75px;
}
.details1 .details-content{
    padding-left: 40px;
}

.details1 h1{
    font-family: "Crimson Text", serif;
    font-weight: bold;
    line-height: 125%;
    font-size: 40px;
    color: #000;
}
.details1 p{
    font-weight: normal;
    line-height: 125%;
    color: #212529;
    opacity: 50%;
    font-size: 15px;
    margin-top: 20px;
    text-align: justify;

}
.details1 a{
    margin-top: 16px !important;
    font-weight: normal;
    line-height: 125%;
    color: #fff;
    font-size: 20px;
    padding: 13px 30px;
    border-radius: 5px;
    background-color: #000;
    list-style: none;
    transition: 0.4s;

}
.details1 a:hover{
    color: #000;
    background-color: #fff;
    border: 2px solid black;

}

@media (max-width: 720px) {
    .details1{
        padding: 0 20px;
        padding-top: 0;
    }
    .detail-img{
        margin-bottom: 30px;
    }
    .details1 h1{
        font-size: 28px;
        padding: 15px 0;
    }
    .details1 .padright,.details1 .details-content{
        padding: 0;
        margin-top: 30px;
    }
    .btn{
        margin-top: 30x;
    }
}