.partners{
    margin-bottom: 100px;
}
.partners .content h6{
    font-size: 16px;
    color: var(--bggreen);
    font-weight: bold;
    line-height: 125%;
    letter-spacing: 5px;
}
.partners .content h1{
    font-family: "Crimson Text", serif;
    font-size: 40px;
    line-height: 125%;
    font-weight: bold;
    padding: 16px 0;
}
.partners .content h5{
    font-size: 20px;
    line-height: 160%;
    text-indent: 10px;
}
.partners-img{
    padding: 55px 0;
}
.btn-learn a{
    font-size: 20px;
    line-height: 125%;
    padding: 13px 30px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    transition: 0.4s;

}
.btn-learn a:hover{
    color: #000;
    background-color: #fff;
    border: 2px solid black;
}
.p-part{
    padding: 0;
}

@media (max-width: 720px) {
    .p-part{
        padding: 50px 90px;
    }
}
